@import './../../../../scss/theme-bootstrap';

.quantity {
  &--menu__container {
    border: 1px solid $color--gray;
    display: flex;
    align-items: center;
    gap: 0;
    height: 48px;
    width: auto;
    justify-content: center;
  }
  &--input {
    background: $color-white;
    font-weight: 800;
    text-align: center;
    width: 48px;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type='number'] {
      border: none;
      -moz-appearance: textfield;
    }
  }
  &--btn {
    background: $color-white;
    border: none;
    padding: 13px 5px;
    cursor: pointer;
    &:hover {
      background: $color-white;
    }
  }
}